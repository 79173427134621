jQuery(document).ready(function ($) {
  // Hide all accordion content initially
  $(".accordion-content").hide();

  // Event handler for accordion header click in the second accordion
  $(".accordion-header-menu").click(function (e) {
    e.preventDefault(); // Prevent default link behavior
    console.log("Clicked");
    // If not visible, toggle visibility of accordion content
    $(this).next(".accordion-content-menu").slideToggle();
    // Slide up all other accordion content
    $(".accordion-content-menu").not($(this).next()).slideUp();
    // Toggle 'expanded' class only for this accordion header
    $(this).toggleClass("expanded");
    // Remove 'expanded' class from headers in the first accordion
    $(".accordion-header-menu").removeClass("expanded");
  });

  // Event handler for accordion header click in the first accordion
  $(".accordion-header").click(function () {
    // Toggle visibility of clicked header's content
    $(this).next(".accordion-content").slideToggle();
    // Slide up all other accordion content
    $(".accordion-content").not($(this).next()).slideUp();
    // Toggle 'expanded' class only for this accordion header
    $(this).toggleClass("expanded");
    // Remove 'expanded' class from headers in the second accordion
    $(".accordion-header").removeClass("expanded");
  });

  // Check if the screen size is less than or equal to 768px (mobile)
});
