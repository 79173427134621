jQuery(document).ready(function ($) {
  // Add click event listener to tab titles
  $(".tab-title").click(function () {
    // Remove 'current' class from all tab titles
    $(".tab-title").removeClass("current");
    // Add 'current' class to the clicked tab title
    $(this).addClass("current");

    // Hide all tab contents
    $(".tab-content").hide();

    // Get the ID of the clicked tab title
    var tabId = $(this).attr("id");
    // Extract the index from the tab ID
    var tabIndex = tabId.split("-")[1];
    // Generate ID of corresponding tab content
    var tabContentId = "#tab-content-" + tabIndex;

    // Show corresponding tab content
    $(tabContentId).show();
  });

  // Show the content of the first tab by default
  $(".tab-title:first").addClass("current");
  $("#tab-content-1").show();
});
