jQuery(document).ready(function ($) {
  let loading = false; // Initialize loading state
  let page = 1; // Initialize page number
  // Load More functionality for Press Coverage
  $("#load-more-press").click(function (e) {
    e.preventDefault();
    console.log("press load more clicked");
    if (!loading) {
      loading = true; // Set loading state to prevent multiple requests
      page++; // Increment the page number

      $.ajax({
        url: ajax_object.ajaxurl, // Use localized ajaxurl
        type: "POST",
        data: {
          action: "load_more_press", // The custom AJAX action we will define
          page: page,
          post_type: "press_coverage", // Specify the custom post type
        },
        success: function (response) {
          if (response) {
            $(".blogs-container .related-posts .container").append(response); // Append new posts
            loading = false; // Reset loading state
          } else {
            $("#load-more-press").hide(); // Hide button if no more posts
          }
        },
      });
    }
  });
});
