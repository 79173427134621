(function ($) {
  const ajaxurl = ajax_object.ajaxurl;
  let page = 1;
  let loading = false;
  let debounceTimeout;

  // Initialize filters from URL on page load
  function setFiltersFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.forEach((value, key) => {
      const elements = $(`[name='${key}[]'], [name='${key}']`);
      if (elements.length) {
        elements.each(function () {
          if (this.tagName === "INPUT" && $(this).attr("type") === "checkbox") {
            if ($(this).val() === decodeURIComponent(value)) {
              $(this).prop("checked", true);
              $(this).siblings("a.checkbox-toggle").addClass("selected");
            }
          } else {
            $(this).val(decodeURIComponent(value));
          }
        });
      }
    });
  }

  // Update URL parameters based on the filtered data
  function updateUrlParams(filteredData) {
    const urlParams = new URLSearchParams();

    // Add new filter data to the URL params
    Object.keys(filteredData).forEach((key) => {
      if (Array.isArray(filteredData[key])) {
        filteredData[key].forEach((v) => {
          urlParams.append(key, encodeURIComponent(v));
        });
      } else {
        urlParams.set(key, encodeURIComponent(filteredData[key]));
      }
    });

    const queryString = urlParams.toString();
    const newUrl = queryString
      ? `${window.location.pathname}?${queryString}`
      : window.location.pathname;

    // Only update the URL if it's different
    if (window.location.href !== newUrl) {
      history.pushState(null, null, newUrl);
    }
  }

  // Main function to filter posts
  function filterPosts(append = false) {
    const formDataArray = $("#filter-form").serializeArray();
    const filteredData = {};

    formDataArray.forEach(({ name, value }) => {
      if (!["nonce", "_wp_http_referer"].includes(name) && value) {
        const cleanName = name.replace(/\[\]$/, ""); // Remove [] from name
        if (filteredData[cleanName]) {
          if (!Array.isArray(filteredData[cleanName])) {
            filteredData[cleanName] = [filteredData[cleanName]];
          }
          filteredData[cleanName].push(value);
        } else {
          filteredData[cleanName] = [value];
        }
      }
    });

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data:
        $.param(filteredData) +
        `&action=filter_reports&page=${page}&nonce=${ajax_object.nonce}`,
      success: (response) => {
        // Remove any existing "No reports found" message by targeting the exact text content
        $("#send-posts p")
          .filter(function () {
            return (
              $(this).text() ===
              "No reports found matching the selected filters."
            );
          })
          .remove();

        if (response.trim() === "") {
          if (page === 1) {
            $("#send-posts").html(
              "<p>No reports found matching the selected filters.</p>"
            );
          } else {
            // Check if the "No reports found" message is already present
            if (
              $("#send-posts p").filter(function () {
                return (
                  $(this).text() ===
                  "No reports found matching the selected filters."
                );
              }).length === 0
            ) {
              $("#send-posts").append(
                "<p>No reports found matching the selected filters.</p>"
              );
            }
          }
          $("#load-more-reports")
            .prop("disabled", true)
            .text("No more reports to load");
        } else {
          if (page === 1 || !append) {
            $("#send-posts").html(response);
          } else {
            $("#send-posts").append(response);
          }
          $("#load-more-reports")
            .prop("disabled", false)
            .text("Load More Reports");
        }
        loading = false;
        updateUrlParams(filteredData);
      },
      error: (xhr, status, error) => {
        console.error("AJAX Error:", error);
        loading = false;
      },
    });
  }

  // Debounce function to limit how often filterPosts can be called
  function debounce(func, wait) {
    return function (...args) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  $(document).ready(() => {
    setFiltersFromUrl();
    filterPosts(); // Fetch posts on initial load

    $("#view-all").on("click", (e) => {
      e.preventDefault();
      $("input[type='checkbox']").prop("checked", false); // Uncheck all checkboxes
      $("#filter-form").submit(); // Submit the filter form

      // Clear the URL parameters
      var baseUrl = window.location.pathname; // Get the base URL without query params
      history.pushState(null, null, baseUrl); // Update the browser's URL without reloading the page

      // Optionally, you can trigger a new filter post request to load all posts
      page = 1; // Reset to the first page
      filterPosts(); // Fetch and display all posts
    });

    $("#filter-form").on("submit", (e) => {
      e.preventDefault();
      page = 1; // Reset page to 1 on new filter submission
      filterPosts();
    });

    $("#filter-form").on(
      "change",
      "input[type='checkbox']",
      debounce(function () {
        page = 1; // Reset page when changing filters
        filterPosts(); // Call filterPosts to update the results
      }, 500) // Increase debounce time to 500ms
    );

    $("#filter-toggle-btn").on("click", () => {
      $("#report-filter").slideToggle();
    });

    // Load More button functionality
    $("#load-more-reports").on("click", function (e) {
      e.preventDefault();
      if (!loading) {
        loading = true;
        page++; // Increment the page number
        filterPosts(true); // Fetch the next set of posts and append them
      }
    });

    // Handle back and forward button navigation
    window.onpopstate = () => {
      setFiltersFromUrl();
      filterPosts();
    };
  });
})(jQuery);
