import "../../../sass/style.scss";
import "../../../js/accordion.js";
import "../../../js/post-filtering.js";
import "../../../js/reports-filtering.js";
import "../../../js/sortby.js";
import "../../../js/count-reports.js";
import "../../../js/navigation-dropdown.js";
import "../../../js/tab-content.js";
import "../../../js/press-filtering.js";

var ajaxurl = ajax_object.ajaxurl;
