/* jQuery(document).ready(function ($) {
  var ajaxurl = ajax_object.ajaxurl;

  // Fetch and display the total count of posts on page load
  fetchTotalCount();

  // Fetch and display the count of filtered results on page load
  fetchFilteredCount(""); // Pass empty string initially

  // Handle form submission
  $("#filter-form").submit(function (e) {
    e.preventDefault(); // Prevent default form submission
    filterPosts(); // Call the function to filter posts
  });

  // Function to filter posts
  function filterPosts() {
    var formData = $("#filter-form").serialize(); // Serialize form data

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: formData + "&action=filter_reports", // Append action
      success: function (response) {
        $("#send-posts").html(response); // Update posts container
        console.log("Posts filtered successfully");

        // Update URL with selected filters
        var urlParams = "?" + formData;
        history.pushState(null, null, window.location.pathname + urlParams);

        // Fetch and display the count of filtered results
        fetchFilteredCount(formData);
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
      },
    });
  }

  // Function to fetch and display the total count of posts
  function fetchTotalCount() {
    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: { action: "get_total_count" }, // Action to get total count
      success: function (response) {
        $("#total-count").text(response); // Update total count display
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
      },
    });
  }

  // Function to fetch and display the count of filtered results
  function fetchFilteredCount(formData) {
    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: formData + "&action=get_filtered_count", // Append action
      success: function (response) {
        var filteredCount = response;
        var totalCount = $("#total-count").text(); // Get the total count displayed
        var message = "Showing " + filteredCount + " out of " + totalCount;
        $("#filtered-count").text(message); // Update filtered count display with the message
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
      },
    });
  }
});
 */
