jQuery(document).ready(function ($) {
  var $tabSection = $("#selabs-tabbed-lists"); // The section to scroll to

  function showTab(tabSlug, updateURL = true, scrollToTab = false) {
    var tab = $(".tab-title a[href$='#" + tabSlug + "']").parent();
    if (tab.length) {
      $(".tab-title").removeClass("current");
      tab.addClass("current");

      $(".tab-content").hide();
      var tabIndex = tab.attr("id").split("-")[1];
      $("#tab-content-" + tabIndex).show();

      // Update URL only when needed
      if (updateURL && history.replaceState) {
        history.replaceState(null, null, "#" + tabSlug);
      }

      // Scroll to the tab section if needed
      if (scrollToTab) {
        $("html, body").animate(
          { scrollTop: $tabSection.offset().top - 50 },
          500
        );
      }
    }
  }

  // Check URL on page load
  var hash = window.location.hash.substring(1);
  if (hash) {
    // If hash exists, show the correct tab
    showTab(hash, false, true);
    console.log("Existis");
    history.replaceState(null, null, "#" + hash);
  } else {
    // If no hash, select the first tab and update the URL
    var firstTabSlug = $(".tab-title:first a").attr("href").split("#")[1];
    showTab(firstTabSlug, true, false);
  }

  // Click event: switch tabs & update URL
  $(".tab-title a").click(function (e) {
    e.preventDefault();
    var tabSlug = this.href.split("#")[1];
    showTab(tabSlug, true, true);
  });

  // Handle browser back/forward navigation
  $(window).on("hashchange", function () {
    var newHash = window.location.hash.substring(1);
    showTab(newHash, false, true);
  });
});
