jQuery(document).ready(function ($) {
  /*   $("ul.menu li.dropdown").hover(
    function () {
      $(".sub-menu-custom").addClass("active");
    },
    function () {
      //$(".sub-menu-custom").removeClass("active");
    }
  );
  // Adding click functionality to remove .active class if clicked outside of .sub-menu-custom
  $(document).on("click", function (event) {
    if (!$(event.target).closest(".sub-menu-custom").length) {
      $(".sub-menu-custom").removeClass("active");
    }
  });
 */
  let timer;

  $("ul.menu li.dropdown").hover(
    function () {
      clearTimeout(timer);
      $(".sub-menu-custom").addClass("active");
    },
    function () {
      timer = setTimeout(function () {
        $(".sub-menu-custom").removeClass("active");
      }, 200); // Adjust the delay as needed
    }
  );

  $(".sub-menu-custom").hover(
    function () {
      clearTimeout(timer);
      $(this).addClass("active");
    },
    function () {
      timer = setTimeout(
        function () {
          $(this).removeClass("active");
        }.bind(this),
        200
      ); // Adjust the delay as needed
    }
  );

  // Adding click functionality to remove .active class if clicked outside of .dropdown or .sub-menu-custom
  $(document).on("click", function (event) {
    if (!$(event.target).closest("li.dropdown, .sub-menu-custom").length) {
      $(".sub-menu-custom").removeClass("active");
    }
  });

  // Get the base URL of the site
  var baseUrl = window.location.origin;

  // Define the paths relative to the base URL
  var arrowBlackSrc =
    baseUrl + "/wp-content/themes/se-labs/assets/build/images/arrow_black.svg";
  var arrowWhiteSrc =
    baseUrl + "/wp-content/themes/se-labs/assets/build/images/arrow_white.svg";

  // Append the images with absolute paths
  $("#wpforms-submit-128").append(
    '<span class="arrow-icon"><img class="initial" src="' +
      arrowBlackSrc +
      '" style="width:12px;height:12px"><img class="hover" src="' +
      arrowWhiteSrc +
      '" style="width:12px;height:12px"></span>'
  );

  $(".menu-toggle").click(function () {
    $(".burger-icon").toggleClass("disable");
    $(".burger-close").toggleClass("active");
  });
});
