jQuery(document).ready(function ($) {
  let page = 1;
  let loading = false;

  // Function to update the URL with the selected category
  function updateUrl(category) {
    var urlParams = new URLSearchParams(window.location.search);
    urlParams.set("category", category); // Set or update the category parameter
    history.pushState(
      null,
      null,
      window.location.pathname + "?" + urlParams.toString()
    );
  }

  // Function to set the category filter from URL on page load
  function setFiltersFromUrl() {
    var urlParams = new URLSearchParams(window.location.search);
    var category = urlParams.get("category");

    if (category) {
      // Remove "active" class from all <li> elements
      $("#blog-filter li").removeClass("active");

      // Add "active" class to the relevant <li>
      $("#blog-filter a[data-category='" + category + "']")
        .closest("li")
        .addClass("active");

      // Trigger the AJAX request to filter posts based on the category
      fetchFilteredPosts(category, 1);
    }
  }

  // Function to fetch filtered posts
  function fetchFilteredPosts(category, pageNum) {
    loading = true; // Prevent multiple requests
    $.ajax({
      type: "POST",
      url: ajax_object.ajaxurl, // Use localized ajaxurl
      data: {
        action: "filter_posts",
        category: category,
        page: pageNum, // Add page number
        nonce: ajax_object.nonce, // Add the nonce
      },
      success: function (response) {
        if (pageNum === 1) {
          $("#send-posts-blog").html(response); // Replace content if it's the first page
        } else {
          $("#send-posts-blog").append(response); // Append content for subsequent pages
        }
        loading = false;
        console.log("Posts loaded successfully");
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
        loading = false;
      },
    });
  }

  // Call setFiltersFromUrl on page load
  setFiltersFromUrl();

  $("#blog-filter a").click(function (e) {
    e.preventDefault();

    // Remove "active" class from all <li> elements
    $("#blog-filter li").removeClass("active");

    // Add "active" class to the clicked <li>
    $(this).closest("li").addClass("active");
    var category = $(this).data("category");

    // Update URL with the selected category
    updateUrl(category);

    // Reset page number and fetch new filtered posts
    page = 1;
    fetchFilteredPosts(category, page);
  });

  // Load More functionality
  $("#load-more-blog").click(function (e) {
    e.preventDefault();
    if (!loading) {
      page++; // Increment the page number
      const urlParams = new URLSearchParams(window.location.search);
      const category = urlParams.get("category") || ""; // Get category from URL if set
      fetchFilteredPosts(category, page); // Fetch the next set of posts
    }
  });

  // Handle back and forward button navigation
  window.onpopstate = function () {
    setFiltersFromUrl();
  };
});
