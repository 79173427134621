/* jQuery(document).ready(function ($) {
  var ajaxurl = ajax_object.ajaxurl;
  // Handle change event for the sort dropdown
  $("#sort-select").change(function () {
    // Trigger form submission to apply the selected sorting option
    $("#filter-form").submit();
  });

  // Handle form submission
  $("#filter-form").submit(function (e) {
    e.preventDefault(); // Prevent default form submission
    // Get the selected sort value
    var sortValue = $("#sort-select").val();
    // Filter posts with the selected sorting option
    filterPosts(sortValue);
  });

  // Function to filter posts
  function filterPosts(sortBy) {
    var formData = $("#filter-form").serialize(); // Serialize form data
    // Append the selected sort value to the form data
    formData += "&sort=" + sortBy;

    $.ajax({
      type: "POST",
      url: ajaxurl,
      data: formData + "&action=filter_reports", // Append action
      success: function (response) {
        $("#send-posts").html(response); // Update posts container
        console.log("success");

        // Update URL with selected filters and sorting option
        var urlParams = "?" + formData;
        history.pushState(null, null, window.location.pathname + urlParams);
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error:", error); // Log AJAX error
      },
    });
  }
});
 */
